import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import SoundButton from "../../components/SoundButton/SoundButton";
import Netflix from "../../components/NetflixLogo/Netflix";
import TitleLogo from "../../components/TitleLogo/TitleLogo";
import GameNumber from "../../components/GameNumber/GameNumber";
import {setActivePanel} from "../../store/mainReducer";
import ServerConnect from "../../service";

const Game6 = () => {

    const dispatch = useDispatch()
    const user = useSelector(state => state.main.user)
    const [isAnswer, setIsAnswer] = useState(false)
    const [isInput, setIsInput] = useState('')
    const isDisabled = (isInput !== '') ? [false, '100%'] : [true, '0%']

    let content = []
    let button = []
    if (isAnswer) {
        content.push(
            <p className="answer-check day-page__answer-check"> Ответ принят!
                <img className="answer-check__frame" src="images/answer-frame.png" alt=""/></p>)
    } else {
        content.push(<input
            className="input-block day-page__input-block"
            type="text"
            onChange={(e)=>setIsInput(e.target.value)}
            placeholder="Написать ответ"/>)
        button.push(<button onClick={onAnswer}
                            disabled={isDisabled[0]}
                            style={{filter:`saturate(${isDisabled[1]})`}}
                            className="answer-button day-page__answer-button">отправить
            ответ</button>)
    }

    function onAnswer(e) {
        setIsAnswer(true)
        ServerConnect.sendPut('/api/user/'+user.vk_id, {"answer6": isInput})
    }

    return (
        <div className="container day-page">
            <GameNumber num={7}/>
            <Netflix/>
            <TitleLogo/>
            <div className="container__content">
                <p className="caption day-page__caption">1 день<span> до акта 2</span></p>
                <p className="text text_size_normal day-page__description">Вы помогли Вай собрать все улики! Разгадайте
                    кодовое слово на записках и введите правильный ответ в поле ниже, чтобы принять участие в розыгрыше
                    20 образов до 1350 RP на выбор!</p>
            </div>
            {content}
            {button}
            <button onClick={() => dispatch(setActivePanel('menu'))}
                            className="button button_text_light container__button">назад</button>
            <SoundButton/>
        </div>
    );
};

export default Game6;