import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {setActivePanel, setMusic} from "../store/mainReducer";
import SoundButton from "../components/SoundButton/SoundButton";

const Init = () => {

    const [progress, setProgress] = useState('progress-10');
    const [isInit, setIsInit] = useState([true, '40%'])
    const dispatch = useDispatch()


    useEffect(()=>{
        setProgress('progress-100')
        setTimeout(()=>{
            setIsInit([...isInit, isInit[0]=false, isInit[1]='100%'])
            },3000 )
    },[progress])

    function start () {
        dispatch(setMusic(true))
        dispatch(setActivePanel('menu'))
    }

    return (
        <div className="container start-screen">
            <a href="#" className="netflix-icon start-screen__netflix-icon">
                <img src="images/netflix-logo.png" width="146" height="40" alt=""/>
            </a>
            <div className="intro-block start-screen__intro-block">
                <a href="#" className="title-logo intro-block__title-logo">
                    <img src="images/title-logo.png" width="262" height="84" alt=""/>
                </a>
                <div className="progerss-bar intro-block__progress-bar">
                    <img className="progerss-bar__bg" width="178" height="22" src="images/loading-bar.png"
                         alt=""/>
                        <img className={"progerss-bar__active "+progress} height="22"
                             src="images/loading-bar.png" alt=""/>
                </div>
            </div>
            <button disabled={isInit[0]} style={{opacity:isInit[1]}} onClick={start} className="button container__button">начать</button>
            <SoundButton/>
        </div>
    )
};

export default Init
