import React from 'react';

const Netflix = () => {
    return (
        <>
            <a href="#" className="netflix-icon container__netflix-icon">
                <img src="images/netflix-logo.png" width="104" height="29" alt=""/>
            </a>
        </>
    );
};

export default Netflix;