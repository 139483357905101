import {createSlice} from "@reduxjs/toolkit";

const mainSlice = createSlice({
    name:'main',
    initialState: {
        activePanel: 'init',
        user: null,
        music: false,
    },
    reducers:{
        setActivePanel(state, action) {
               state.activePanel = action.payload
        },
        setUser(state,action){
            state.user = action.payload
        },
        setMusic(state,action){
            state.music = action.payload
        }
    }
})

export default mainSlice.reducer
export const {setActivePanel,setUser, setMusic} = mainSlice.actions
