import React from 'react';

const MenuItem = (props) => {

    let cl = 'link_disabled'
    let url = `images/number${props.game}.png`
    if (props.status === 'active') {
        cl = 'menu-item_active'
        url = `images/number${props.game}-active.png`
    }
    let path = 'https://vk.com'
    if (props.game == 3) {
        path = 'https://vk.com/wall-49423435_2585849'
    } else if (props.game == 4) {
        path = 'https://vk.com/wall-49423435_2616184'
    } else if (props.game == 5) {
        path = 'https://vk.com/wall-49423435_2625482'
    } else if (props.game == 6) {
        path = 'https://vk.com/wall-49423435_2659202'
    }

    return (
        <>
            <a href={path} target="_blank" id={`${props.game}`} className={`menu-item ${cl}`}>
                <img src={url} width="87" height="87" alt=""/>
            </a>
        </>
    );
};

export default MenuItem
