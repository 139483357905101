import React, {useEffect} from 'react';
import Init from "./panels/Init";
import Dashboard from "./panels/Dashboard";
import {useDispatch, useSelector} from "react-redux";
import bridge from "@vkontakte/vk-bridge";
import {setUser} from "./store/mainReducer";
import Game1Result from "./games/Game1/Game1Result";
import Game7 from "./games/Game7/Game7";
import Game6 from "./games/Game6/Game6";
import Game1 from "./games/Game1/Game1";
import ServerConnect from "./service";

const App = () => {

    const dispatch = useDispatch()
    const mus = document.querySelector("#bg_sound")
    const activePanel = useSelector(state => state.main.activePanel)
    const music = useSelector(state=>state.main.music)

    useEffect(()=>{
        if (music) {
            mus.play()
        } else {
            mus.pause()
        }
    },[music])

    useEffect(() => {
        async function fetchData() {
            const user = await bridge.send('VKWebAppGetUserInfo');
            if (user) {
                return user;
            }
        }

        fetchData().then(data => get_user_info(data))

    }, [])

    async function get_user_info (user) {
        let response = await fetch(ServerConnect.api_url + '/api/user/' + user.id);
        if (response.status === 204) {
            let context = {
                vk_id: user.id,
                first_name: user.first_name,
                last_name: user.last_name,
                url_photo: user.photo_200,
            }
            ServerConnect.sendPost('/api/user/'+user.id, context)
                .then(data => dispatch(setUser(data)))
        } else {
            let user_info = await response.json()
            dispatch(setUser(user_info))
        }
    }

    let content = []

    if (activePanel === 'init') {
        content.push(<Init/>)
    } else if (activePanel === 'menu') {
        content.push(<Dashboard/>)
    } else if (activePanel === 'game1') {
        content.push(<Game1/>)
    } else if (activePanel === 'game1result') {
        content.push(<Game1Result/>)
    } else if (activePanel === 'game7') {
        content.push(<Game6/>)
    } else if (activePanel === 'game2') {
        content.push(<Game7/>)
    }

        return (
            <div className='app'>
                {content}
            </div>
        );
}
export default App;
