import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import SoundButton from "../../components/SoundButton/SoundButton";
import Netflix from "../../components/NetflixLogo/Netflix";
import TitleLogo from "../../components/TitleLogo/TitleLogo";
import GameNumber from "../../components/GameNumber/GameNumber";
import {setActivePanel} from "../../store/mainReducer";
import ServerConnect from "../../service";

const Game7 = () => {

    const dispatch = useDispatch()
    const user = useSelector(state=>state.main.user)

    return (
        <div className="container final-page">
            <GameNumber num={2}/>
            <Netflix/>
            <TitleLogo/>
            <div className="container__content">
                <p className="caption caption_size_normal caption_align_center final-page__caption">Смотрите премьеру
                    “Аркейн”
                    сегодня в 11:30 МСК!</p>
                <div className="video-block final-page__video-block">
                    <div className="video-block__video">
                        <a href='https://lp.ru.leagueoflegends.com/' target='_blank'>
                            <img loading='lazy' width='100%' src="images/img_video.jpg" alt="картинка трансляции"/>
                        </a>
                    </div>
                    <p className="text text_size_normal video-block__text">Вас ждет праздничная трансляция с интересными
                        гостями и развлекательными шоу, а еще — возможность выиграть сундуки за просмотр</p>
                </div>
                <a href='https://lp.ru.leagueoflegends.com/' target='_blank' className="watch-button final-page__watch-button">смотреть</a>
            </div>
            <button onClick={() => dispatch(setActivePanel('menu'))}
                    className="button button_text_light container__button">назад</button>
            <SoundButton/>
        </div>
    );
};

export default Game7;