import React from 'react';
import ReactDOM from 'react-dom';
import bridge from '@vkontakte/vk-bridge';
import App from './App';
import {Provider} from 'react-redux';
import {store} from './store';

bridge.send("VKWebAppInit");
bridge.send("VKWebAppSetViewSettings", {"status_bar_style": "light", "action_bar_color": "#fff"});

ReactDOM.render(
    <Provider store={store}>
      <React.StrictMode>
        <App/>
      </React.StrictMode>
    </Provider>,
  document.getElementById('root')
);