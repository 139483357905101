import React from 'react';
import {useDispatch} from "react-redux";
import {setActivePanel} from "../../store/mainReducer";

const MenuItem = (props) => {

    const dispatch = useDispatch()
    let cl = ''
    let url = `images/number${props.game}.png`
    let isActive = true
    if (props.status === 'active') {
        cl = 'menu-item_active'
        url = `images/number${props.game}-active.png`
        isActive = false
    }


    function go () {
        dispatch(setActivePanel(`game${props.game}`))
    }
    return (
        <>
        <button disabled={isActive} id={`${props.game}`} onClick={go} className={`menu-item ${cl}`}>
            <img src={url} width="87" height="87" alt=""/>
        </button>
        </>
    );
};

export default MenuItem
