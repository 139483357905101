import React from 'react';

const GameNumber = (props) => {
    return (
            <div className="container__game-number">
                <img width="93" height="93" src={`images/number${props.num}-active.png`} alt=""/>
            </div>
    );
};

export default GameNumber;