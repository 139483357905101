import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import SoundButton from "../../components/SoundButton/SoundButton";
import Netflix from "../../components/NetflixLogo/Netflix";
import TitleLogo from "../../components/TitleLogo/TitleLogo";
import GameNumber from "../../components/GameNumber/GameNumber";
import {setActivePanel} from "../../store/mainReducer";

const Game1 = () => {

    const dispatch = useDispatch()
    const [isActivate, setIsActivate] = useState(false)
    const onFingered = (isActivate) ? 'none' : 'block'

    function finger() {
        setIsActivate(true)
        setTimeout(()=>{
            dispatch(setActivePanel('game1result'))
        },3000)
    }

    let content = []
    let animation = []
    if (isActivate) {
        content.push(
        <p className="caption caption_size_big caption_align_center first-game-page__analysis">
            Идет хекстек-анализ
        </p>)
        animation.push(
            <div className="diamond-block__scanner">
                <div className="diamond-block__scanner-rail"></div>
            </div>
        )
    } else {
        content.push(
        <p className="text text_size_big text_align_center first-game-page__text">
            Ваш персонаж из мира “Аркейн”!
            Пройдите хекстек-исследование и получите персональную аватарку</p>)
    }

    return (
        <div className="container first-game-page">
            <GameNumber num={1}/>
            <Netflix/>
            <TitleLogo/>
            <div className="container__content">
                <button onClick={finger} className="diamond-block first-game-page__diamond-block">
                    <img className="diamond-block__diamond-pic" src="images/diamond-pic.png" width="672"
                         height="370" alt=""/>
                    {animation}
                </button>)
                {content}
            </div>
            <button onClick={()=>dispatch(setActivePanel('menu'))}
                    className="button container__button"
                    style={{display:onFingered}}
                    >назад
            </button>
            <SoundButton/>
        </div>
    );
};

export default Game1;