import React from 'react';

const TitleLogo = () => {
    return (
        <>
            <a href="#" className="title-logo container__title-logo">
                <img src="images/title-logo.png" width="117" height="38" alt=""/>
            </a>
        </>
    );
};

export default TitleLogo;