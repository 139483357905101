import React from 'react';
import MenuItemList from "../components/ManuItemList/MenuItemList";
import SoundButton from "../components/SoundButton/SoundButton";
import Netflix from "../components/NetflixLogo/Netflix";
import TitleLogo from "../components/TitleLogo/TitleLogo";

const Dashboard = () => {

    return (
        <div className="container menu-page">
            <Netflix/>
            <TitleLogo/>
            <div className="container__content">
                <MenuItemList/>
                <p className="text text_size_big text_align_center menu-page__title">Погрузитесь в мир сериала «Аркейн»!</p>
                <p className="text text_size_normal text_align_center menu-page__description">Познакомьтесь ближе с
                    героями картины,
                    участвуйте в расследовании Вай и поборитесь за награды</p>
            </div>
            <SoundButton/>
        </div>
    );
};

export default Dashboard;