import React, {useEffect, useState} from 'react';
import SoundButton from "../../components/SoundButton/SoundButton";
import Netflix from "../../components/NetflixLogo/Netflix";
import TitleLogo from "../../components/TitleLogo/TitleLogo";
import GameNumber from "../../components/GameNumber/GameNumber";
import {setActivePanel} from "../../store/mainReducer";
import {useDispatch, useSelector} from "react-redux";

const Game1Result = () => {
    const dispatch = useDispatch()
    const user = useSelector(state => state.main.user)
    const [character, setCharacter] = useState({name:'', path:''})

    useEffect(() => {

        function getRandomInt(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min)) + min; //Максимум не включается, минимум включается
        }

        function getCharacter() {
            const num = getRandomInt(1, 9)
            let char = characters[num]
            setCharacter(char)
        }

            getCharacter()
    }, [])


    const characters = {
        1 : {name:'Кейтлин', path: 'character-caitlyn.jpg', text:'«Наверное, я тоже отщепенка»'},
        2 : {name:'Джинкс', path: 'character-jinx.jpg', text:'«Монстрами рождаются или становятся?»'},
        3 : {name:'Мел Медарда', path: 'character-mel.jpg', text:'«Любая стоящая затея - это риск»'},
        4 : {name:'Силко', path: 'character-silco.jpg', text:'«Ты умрешь за идею, но не готов сражаться за нее»'},
        5 : {name:'Вандер',path: 'character-vander.jpg', text:'«У тебя доброе сердце. Сохрани его. Как бы мир не пытался тебя сломать»'},
        6 : {name:'Вай', path: 'character-vi.jpg',text:'«В уникальности твоя сила»'},
        7 : {name:'Виктор',path: 'character-viktor.jpg', text:'«Если хочешь изменить мир, не проси разрешения»'},
        8 : {name:'Джейс', path: 'character-jay.jpg', text:'«Мы - город прогресса, и наше будущее будет ярким»'},
    }
    return (
        <div className="container first-game-page">
            <GameNumber num={1}/>
            <Netflix/>
            <TitleLogo/>
            <div className="container__content">
                <p className="text text_size_big first-game-page__result-text">Исследование показало,
                    что&nbsp;вы&nbsp;соответствуете:</p>
                <div className="character-block">
                    <a href={`https://arcane.techbot24.ru/static/img/${character.path}`} target="_blank" className="character-image">
                        <img className="character-image__user" width="184" height="184" decoding="async" loading='lazy'
                            src={`images/${character.path}`} alt=""/>
                    </a>
                    <div className="character-info">
                        <p className="caption caption_size_xl character-info__caption">{character.name}</p>
                        <p className="text text_size_normal character-info__description">{character.text}</p>
                    </div>
                </div>
            </div>
            <p className="text text_size_normal" style={{textAlign:"center", paddingTop:"20px"}}>
                Нажмите на аватарку, чтобы сохранить ее!
            </p>
            <p className="text text_size_normal" style={{textAlign:"center", padding:"20px"}}>Пройдите исследование заново, чтобы получить новую аватарку.</p>
            <button onClick={()=> dispatch(setActivePanel('menu'))} className="button container__button">назад</button>
            <SoundButton/>
        </div>
    );
};

export default Game1Result;
